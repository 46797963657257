<template>
  <v-container fluid class="bg-white px-8 visit-create-page">
    <v-form
      ref="createVisitForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="validateJob()"
    >
      <v-row>
        <v-col md="12" cols="12">
          <div class="form-action">
            <div class="form-title">
              Assessment for
              <span
                class="form-title-link cursor-pointer text-h5"
                v-on:click="openDialog('customer')"
              >
                <template v-if="vCustomerId">{{
                  vCustomer.display_name
                }}</template>
                <template v-else>
                  Select a Customer
                  <v-tooltip bottom content-class="custom-bottom-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark text v-bind="attrs" v-on="on">
                        <v-icon large color="cyan"
                          >mdi-account-plus-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Select Customer</span>
                  </v-tooltip>
                </template>
              </span>
              <template v-if="!vCustomerId">
                <span class="px-5 custom-blue-color"> OR </span>
                <span
                  class="form-title-link cursor-pointer text-h5"
                  v-on:click="createCustomerDialog()"
                >
                  Create New Customer
                  <v-tooltip bottom content-class="custom-bottom-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark text v-bind="attrs" v-on="on">
                        <v-icon large color="cyan"
                          >mdi-plus-circle-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Create New Customer</span>
                  </v-tooltip>
                </span>
              </template>
            </div>
            <div style="width: 175px">
              <v-btn
                :disabled="update_loading"
                class="custom-bold-button custom-grey-border"
                depressed
                v-on:click="goBack()"
                >Cancel</v-btn
              >
              <v-btn
                class="custom-bold-button ml-4"
                depressed
                :disabled="!formValid || formLoading || update_loading"
                :loading="formLoading"
                v-on:click="validateJob()"
                color="cyan white--text"
                >Save</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col md="7">
          <label for="assess-title" class="required">Assessment Title</label>
          <v-text-field
            id="assess-title"
            dense
            filled
            placeholder="Title"
            solo
            flat
            v-model="dbAssess.title"
            color="cyan"
            :rules="[
              validateRules.required(dbAssess.title, 'Title'),
              validateRules.maxLength(dbAssess.title, 'Title', 100),
            ]"
          />
          <v-textarea
            auto-grow
            dense
            filled
            color="cyan"
            v-model="dbAssess.description"
            placeholder="Description"
            solo
            v-on:keypress="(e) => manageLimit(e)"
            v-on:paste="(e) => onPaste(e)"
            flat
            row-height="25"
            :rules="[
              validateRules.maxLength(
                dbAssess.description,
                'Description',
                1024
              ),
            ]"
          />
          <div md="7" class="text-right">
            {{ dbAssess.description ? dbAssess.description.length : 0 }}/200
          </div>
          <v-row>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3
                  ><label class="my-0">Billing Location</label></v-flex
                >
                <v-flex md9 class="position-relative">
                  <v-icon
                    v-on:click="openDialog('billing')"
                    small
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBilling?.id">
                <span v-if="vBilling && vBilling.property_address">
                  <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                >
                <p v-if="vBilling && vBilling.property_address">
                  {{ vBilling.street_1 }},
                  <br v-if="vBilling.street_2 || vBilling.unit_no" />
                  <template v-if="vBilling.street_2">
                    {{ vBilling.street_2 }},
                  </template>
                  <template v-if="vBilling.unit_no">
                    {{ vBilling.unit_no }},
                  </template>
                  <br />
                  {{ vBilling.country }}
                  <template v-if="vBilling && vBilling.zip_code != '000000'">
                    , {{ vBilling.zip_code }}
                  </template>
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select billing location</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('billing-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>
              <template v-if="vBillingContact?.id">
                <p class="mb-0">{{ vBillingContact.display_name }}</p>
                <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                <p class="mb-0">{{ vBillingContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>
              <v-layout class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="dbAssess.billing_contact_person"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6">
              <v-layout class="align-center">
                <v-flex md3><label class="my-0">Site Location</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vProperty?.id">
                <span v-if="vProperty && vProperty.property_address">
                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                >
                <p class="mr-2" v-if="vProperty && vProperty.property_address">
                  {{ vProperty.street_1 }},
                  <br v-if="vProperty.street_2 || vProperty.unit_no" />
                  <template v-if="vProperty.street_2">
                    {{ vProperty.street_2 }},
                  </template>
                  <template v-if="vProperty.unit_no">
                    {{ vProperty.unit_no }},
                  </template>
                  <br />
                  {{ vProperty.country }}
                  <template v-if="vProperty && vProperty.zip_code != '000000'">
                    , {{ vProperty.zip_code }}
                  </template>
                </p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select site location</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout class="mt-3 align-center">
                <v-flex md3><label class="my-0">Contact Details</label></v-flex>
                <v-flex md9 class="position-relative">
                  <v-icon
                    small
                    v-on:click="openDialog('property-contact')"
                    class="py-0 my-0"
                    color="cyan"
                    style="position: absolute; top: -8px"
                    >mdi-pencil</v-icon
                  >
                </v-flex>
              </v-layout>

              <template v-if="vPropertyContact?.id">
                <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
              </template>
              <template v-else-if="vCustomer?.id">
                <p class="mb-0 red--text">Please select contact person</p>
              </template>
              <template v-else>
                <p class="mb-0 red--text">Please select customer</p>
              </template>

              <v-layout class="mt-3">
                <v-flex md4
                  ><label class="my-0">Email Notification </label></v-flex
                >
                <v-flex md8>
                  <v-switch
                    v-model="dbAssess.property_contact_person"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
          <div>
            <label>Contract Number </label>
            <v-autocomplete
              id="contract"
              dense
              v-model="dbAssess.contract"
              :items="vOption.contract_list"
              item-text="barcode"
              v-on:click:clear="contractDetail = {}"
              clearable
              item-color="cyan"
              v-on:change="getContractDetail($event)"
              item-value="id"
              filled
              hide-details
              placeholder="Contract"
              solo
              flat
              color="cyan"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title class="px-0">{{
                    data.item.job_title
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.barcode
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div>
                    Start Date : {{ formatDate(data.item.contract_start) }}
                  </div>
                  <div>End Date : {{ formatDate(data.item.contract_end) }}</div>
                </v-list-item-action>
              </template>
            </v-autocomplete>
            <v-list
              v-if="
                dbAssess.contract &&
                contractDetail &&
                contractDetail.is_contract
              "
            >
              <v-list-item class="grey lighten-4 ml-1">
                <v-list-item-content>
                  <v-list-item-title class="px-0">
                    <h5>
                      {{ contractDetail.job_title }}
                    </h5>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    contractDetail.barcode
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex">
                    <div class="mx-5">
                      <v-chip class="mr-2" color="blue" outlined>
                        <span class="font-size-15">{{
                          contractDetail.total_service
                        }}</span>
                      </v-chip>
                      <b>Total Assessment </b>
                    </div>
                    <div class="mx-5">
                      <v-chip class="mr-2" color="red" outlined>
                        <span class="font-size-15">{{
                          contractDetail.visit_count
                        }}</span>
                      </v-chip>
                      <b>Consumed </b>
                    </div>
                    <div class="mx-5">
                      <v-chip class="mr-2" color="orange" outlined>
                        <span class="font-size-15">{{
                          getBalance(contractDetail)
                        }}</span>
                      </v-chip>
                      <b>Balance</b>
                    </div>
                  </div>
                </v-list-item-action>
                <v-list-item-action>
                  <div>
                    <b>Start Date </b> :
                    {{ formatDate(contractDetail.contract_start) }}
                  </div>
                  <div>
                    <b>End Date </b>:
                    {{ formatDate(contractDetail.contract_end) }}
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col md="5">
          <table width="100%" style="table-layout: fixed">
            <tr>
              <td width="20%">
                <div class="d-flex ml-1 mt-4">
                  <v-btn
                    depressed
                    tile
                    :outlined="!(dbAssess.option == 'internal')"
                    v-on:click="dbAssess.option = 'internal'"
                    value="internal"
                    color="red darken-3 white--text"
                    style="font-size: 15px; margin-right: 20px"
                    >Internal</v-btn
                  >
                  <v-btn
                    style="font-size: 15px; margin-right: 20px"
                    class="mx-2"
                    depressed
                    tile
                    :outlined="!(dbAssess.option == 'external')"
                    v-on:click="dbAssess.option = 'external'"
                    value="external"
                    color="green darken-4 white--text"
                    >External</v-btn
                  >
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td width="20%">
                <label for="job-id">Creation Date </label>
              </td>
              <td>
                <v-text-field
                  id="job-id"
                  dense
                  filled
                  readonly
                  hide-details
                  placeholder="Job #"
                  solo
                  flat
                  :value="todayTime"
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <template v-if="scheduleTab == 'recurring'">
                <td width="20%">
                  <label for="job-id">Job # </label>
                </td>
                <td>
                  <v-text-field
                    id="job-id"
                    dense
                    filled
                    readonly
                    hide-details
                    placeholder="Job #"
                    solo
                    flat
                    :value="
                      vOption && vOption.barcode ? `${vOption.barcode}` : ''
                    "
                    color="cyan"
                  />
                </td>
              </template>
              <template v-else>
                <td width="20%">
                  <label for="job-id">Assessment / Job # </label>
                </td>
                <td>
                  <v-text-field
                    id="job-id"
                    dense
                    filled
                    readonly
                    hide-details
                    placeholder="Assessment / Job #"
                    solo
                    flat
                    :value="
                      vOption && vOption.assess_barcode
                        ? `${vOption.assess_barcode} / ${vOption.barcode}`
                        : assessBarcode
                        ? `${assessBarcode} / ${ticketBarcode}`
                        : ''
                    "
                    color="cyan"
                  />
                </td>
              </template>
            </tr>
            <tr>
              <td width="20%">
                <label for="priority">Priority</label>
              </td>
              <td>
                <div class="d-flex ml-1">
                  <v-btn
                    depressed
                    tile
                    :outlined="!(dbAssess.priority == 1)"
                    v-on:click="dbAssess.priority = 1"
                    value="1"
                    color="red darken-3 white--text"
                    >High</v-btn
                  >
                  <v-btn
                    class="mx-2"
                    depressed
                    tile
                    :outlined="!(dbAssess.priority == 2)"
                    v-on:click="dbAssess.priority = 2"
                    value="2"
                    color="orange darken-4 white--text"
                    >Medium</v-btn
                  >
                  <v-btn
                    depressed
                    tile
                    :outlined="!(dbAssess.priority == 3)"
                    v-on:click="dbAssess.priority = 3"
                    value="3"
                    color="light-green darken-3 white--text"
                    >Low</v-btn
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label for="reference-no">Reference #</label>
              </td>
              <td>
                <v-text-field
                  id="reference-no"
                  dense
                  filled
                  v-model="dbAssess.reference"
                  hide-details
                  placeholder="Reference #"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="job-type" class="required">Job Type</label>
              </td>
              <td>
                <v-autocomplete
                  id="job-type"
                  dense
                  multiple
                  v-model="dbAssess.job_type"
                  :items="vOption.ticket_types"
                  item-text="value"
                  item-color="cyan"
                  item-value="value"
                  filled
                  hide-details
                  placeholder="Job Type"
                  solo
                  flat
                  color="cyan"
                  :rules="[
                    validateRules.required(dbAssess.job_type, 'Job Type'),
                  ]"
                />
              </td>
            </tr>

            <tr>
              <td>
                <label for="tag">Quotation Number</label>
              </td>
              <td>
                <v-autocomplete
                  id="job-type"
                  dense
                  multiple
                  v-model="dbAssess.quotation"
                  :items="vOption.quotations"
                  item-text="value"
                  item-color="cyan"
                  item-value="value"
                  filled
                  hide-details
                  placeholder="Quotation Number"
                  solo
                  flat
                  color="cyan"
                >
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td>
                <label for="po-number">PO Number</label>
              </td>
              <td>
                <v-text-field
                  id="po-number"
                  dense
                  v-model="dbAssess.po_number"
                  filled
                  hide-details
                  placeholder="PO Number"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label for="payment-mode">Payment Mode</label>
              </td>
              <td>
                <v-autocomplete
                  id="payment-mode"
                  dense
                  v-model="dbAssess.payment_mode"
                  :items="vOption.payment_modes"
                  item-text="value"
                  item-color="cyan"
                  item-value="id"
                  filled
                  hide-details
                  placeholder="Payment Mode"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Tags </label>
              </td>
              <td>
                <TagAutoComplete type="visit" v-model="dbAssess.tags" />
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="job-schedule" ref="engineer">
          <div class="d-flex mb-3 grey lighten-3 py-3">
            <v-checkbox
              v-model="dbAssess.schedule_later"
              class="ma-0 p-0"
              color="blue darken-4"
              id="Schedule__later"
              dense
              inset
            />
            <div>
              <label for="Schedule__later" style="font-size: 15px">
                <span class="color-custom-blue"> Schedule later </span>
              </label>
              <div class="pl-1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Recusandae, earum.
              </div>
            </div>
          </div>
          <template v-if="!dbAssess.schedule_later">
            <v-tabs
              v-model="scheduleTab"
              background-color="transparent"
              centered
              color="cyan"
              icons-and-text
              class="mb-3"
              v-on:change="setScheduleType()"
            >
              <v-tab
                href="#one-off"
                :disabled="this.do_update == 1 ? true : false"
              >
                <div class="custom-tab-title d-flex">
                  <div class="custom-tab-icon pr-2">
                    <v-icon left large color="gray">mdi-calendar</v-icon>
                  </div>
                  <div class="text-left">
                    <h3 class="text-capitalize mb-1">One-Off Job</h3>
                    <p class="m-0 text-capitalize">
                      A one time job with one or more assessment
                    </p>
                  </div>
                </div>
              </v-tab>
              <v-tab
                href="#recurring"
                :disabled="this.do_update == 1 ? true : false"
              >
                <div class="custom-tab-title d-flex">
                  <div class="custom-tab-icon pr-2">
                    <v-icon left large color="gray"
                      >mdi-calendar-multiple</v-icon
                    >
                  </div>
                  <div class="text-left">
                    <h3 class="text-capitalize mb-1">Recurring Job</h3>
                    <p class="m-0 text-capitalize">
                      A contract job with repeating assessment
                    </p>
                  </div>
                </div>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="scheduleTab">
              <v-tab-item value="one-off">
                <div class="border">
                  <div
                    class="py-3 grey lighten-4"
                    @click="show_one_off_job = !show_one_off_job"
                  >
                    <div class="d-flex align-start">
                      <div class="px-3">
                        <v-icon>{{
                          !show_one_off_job
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                      </div>
                      <div>
                        <h3 class="text-capitalize color-custom-blue">
                          One-Off Job
                        </h3>
                        <p class="m-0 text-capitalize">
                          A one time job with one or more assessment
                        </p>
                      </div>
                    </div>
                  </div>
                  <v-expand-transition>
                    <div class="px-3" v-show="show_one_off_job">
                      <Schedule :assess-data="assessData" />
                    </div>
                  </v-expand-transition>
                </div>
              </v-tab-item>
              <v-tab-item value="recurring">
                <div class="border">
                  <div
                    class="py-3 grey lighten-4"
                    @click="show_recurring_job = !show_recurring_job"
                  >
                    <div class="d-flex align-start">
                      <div class="px-3">
                        <v-icon>{{
                          !show_recurring_job
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                      </div>
                      <div>
                        <h3 class="text-capitalize color-custom-blue">
                          Recurring Job
                        </h3>
                        <p class="m-0 text-capitalize">
                          A contract job with repeating assessment
                        </p>
                      </div>
                    </div>
                  </div>
                  <v-expand-transition>
                    <div class="px-3" v-if="show_recurring_job">
                      <RecurringSchedule
                        :ticket-details="updatedTicket"
                        :visit-details="updatedAssess"
                      />
                    </div>
                  </v-expand-transition>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </template>
        </v-col>
        <v-col cols="12" ref="lineItem">
          <div class="border">
            <div
              class="py-3 grey lighten-4"
              @click="show_line_items = !show_line_items"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_line_items ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">Line Items</h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-2" v-if="show_line_items">
                <LineItemV2
                  is-job
                  :db-line-items="dbLineItems"
                  :db-equipments="dbEquipments"
                  :ticket-detail="updatedTicket"
                />
              </div>
            </v-expand-transition>
          </div>
          <v-card
            v-if="false"
            flat
            class="custom-grey-border remove-border-radius"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Line Items
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <LineItemV2
                is-job
                :db-line-items="dbLineItems"
                :db-equipments="dbEquipments"
                :ticket-detail="updatedTicket"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <div class="border">
            <div
              class="py-3 grey lighten-4"
              @click="show_terms_and_conditions = !show_terms_and_conditions"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_terms_and_conditions
                      ? "mdi-chevron-down"
                      : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">
                    Terms &amp; Conditions
                  </h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-3" v-if="show_terms_and_conditions">
                <v-autocomplete
                  id="payment-mode"
                  dense
                  v-model="dbAssess.term_conditions_type"
                  :items="vOption.term_conditions_types"
                  item-text="value"
                  item-color="cyan"
                  item-value="id"
                  class="pl-0 mb-3"
                  filled
                  hide-details
                  placeholder="Select Terms and conditions"
                  solo
                  flat
                  color="cyan"
                />
                <TinyMCE v-model="dbAssess.term_conditions" />
              </div>
            </v-expand-transition>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="border">
            <div
              class="py-3 grey lighten-4"
              @click="show_notes_and_attachment = !show_notes_and_attachment"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_notes_and_attachment
                      ? "mdi-chevron-down"
                      : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">
                    Notes &amp; Attachments
                  </h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-2" v-if="show_notes_and_attachment">
                <v-row>
                  <v-col cols="6">
                    <p class="font-weight-600 mb-2">
                      Client Note
                      <span class="text--red"> (visible in PDF)</span>
                    </p>
                    <TinyMCE v-model="dbAssess.client_remark" />
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex">
                      <span class="font-weight-600">
                        Admin Note (internal use only)
                      </span>
                      <v-spacer></v-spacer>
                      <span
                        class="font-weight-600 d-flex"
                        style="margin-top: -3px !important"
                      >
                        <v-checkbox
                          color="cyan"
                          v-model="dbAssess.is_remark_show"
                        ></v-checkbox>
                        <span class="mt-1">Show In Application</span>
                      </span>
                    </div>
                    <TinyMCE v-model="dbAssess.admin_remark" />
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <p class="font-weight-600 mb-1">
                      Attachments
                      <span class="text--red">(visible in PDF)</span>
                    </p>
                    <table width="100%" style="table-layout: fixed">
                      <tr
                        v-for="(row, index) in vPdfAttachments"
                        :key="`create-visit-attachment-${index}`"
                      >
                        <td class="px-0 py-0">
                          <v-file-input
                            hide-details
                            class="px-0"
                            dense
                            filled
                            solo
                            flat
                            v-model="row.file"
                            placeholder="Select File (images only)"
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon=""
                            prepend-inner-icon="$file"
                            v-on:change="
                              updateFileName('vPdfAttachments', index, $event)
                            "
                            color="cyan"
                          />
                        </td>
                        <td class="py-0">
                          <v-text-field
                            hide-details
                            class="px-0"
                            v-model="row.name"
                            placeholder="Name"
                            dense
                            filled
                            :suffix="row.ext ? `.${row.ext}` : ''"
                            solo
                            flat
                            color="cyan"
                          />
                        </td>
                        <td class="px-0 py-0" width="10%" align="center">
                          <v-btn
                            v-on:click="removeFile('vPdfAttachments', index)"
                            icon
                            class="w-100"
                            color="red lighten-1"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-0 pb-0" colspan="3">
                          <v-btn
                            color="cyan white--text"
                            class="custom-bold-button"
                            depressed
                            v-on:click="addFile('vPdfAttachments')"
                            tile
                            >Add More</v-btn
                          >
                        </td>
                      </tr>
                    </table>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <p class="font-weight-600 mb-1">
                      Attachments (internal use only)
                    </p>
                    <table width="100%" style="table-layout: fixed">
                      <tr
                        v-for="(row, index) in vAttachments"
                        :key="`create-visit-attachment-${index}`"
                      >
                        <td class="px-0 py-0">
                          <v-file-input
                            hide-details
                            class="px-0"
                            dense
                            filled
                            solo
                            flat
                            v-model="row.file"
                            placeholder="Select File"
                            prepend-icon=""
                            prepend-inner-icon="$file"
                            v-on:change="
                              updateFileName('vAttachments', index, $event)
                            "
                            color="cyan"
                          />
                        </td>
                        <td class="py-0">
                          <v-text-field
                            hide-details
                            class="px-0"
                            v-model="row.name"
                            placeholder="Name"
                            dense
                            filled
                            :suffix="row.ext ? `.${row.ext}` : ''"
                            solo
                            flat
                            color="cyan"
                          />
                        </td>
                        <td class="px-0 py-0" width="10%" align="center">
                          <v-btn
                            v-on:click="removeFile('vAttachments', index)"
                            icon
                            class="w-100"
                            color="red lighten-1"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-0 pb-0" colspan="3">
                          <v-btn
                            color="cyan white--text"
                            class="custom-bold-button"
                            depressed
                            v-on:click="addFile('vAttachments')"
                            tile
                            >Add More</v-btn
                          >
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <template>
      <customer-create :key="`visit-customer-create-${dialog_key}`" />
    </template>
    <RouteDialog
      :dialog="routeDialog"
      :db-assess="dbAssess"
      :schedule-tab="scheduleTab"
      :loading="formLoading"
      :do-update="do_update"
      v-on:close="routeDialog = false"
      v-on:scroll-to="scrollTo($event)"
      v-on:submit="onSubmit($event)"
    />
    <CustomerSelect :dialog="customerDialog" v-on:close="closeDialogWithData" />
    <PersonSelect
      :dialog="contactDialog"
      v-on:close="contactDialog = false"
      :type="contactType"
    />
    <AddressSelect
      :dialog="propertyDialog"
      v-on:close="propertyDialog = false"
      :type="propertyType"
      :label="propertyLabel"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RouteDialog from "@/view/components/RouteDialog.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import RecurringSchedule from "@/view/pages/job/visit-partials/Recurring-Schedule.vue";
import Schedule from "@/view/pages/job/visit-partials/Schedule.vue";
import LineItemV2 from "@/view/pages/partials/Line-Item-V2.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
/*import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import { JobEventBus } from "@/core/lib/job/job.lib";*/
import { SET_CREATE_DIALOG_STATUS } from "@/core/services/store/customer.module";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import {
  GET_OPTION,
  SET_SCHEDULE_TYPE,
  SET_CUSTOMER,
  RESET_STATE,
  SET_BILLING,
  SET_BILLING_CONTACT,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_VISIT_TEAM,
  SET_AVAILABLE_TEAM,
  RESET_CREATE_STATE,
  SET_EDIT_RECURRING_SCHEDULE,
} from "@/core/services/store/visit.module";
import { UPLOAD, POST, GET } from "@/core/services/store/request.module";
import { toSafeInteger } from "lodash";
import ObjectPath from "object-path";
/* import Barcode from "@/view/pages/partials/Barcode.vue"; */
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-job",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      formValid: false,
      formLoading: false,
      show_one_off_job: false,
      show_recurring_job: false,
      show_line_items: false,
      show_terms_and_conditions: false,
      show_notes_and_attachment: false,
      dialog_key: Number(new Date()),
      contractList: [
        {
          display_name: "John Deo",
          barcode: "CO-00034",
          start_date: "12/11/2023",
          end_date: "12/12/2023",
        },
        {
          display_name: "Peter",
          barcode: "CO-00044",
          start_date: "12/11/2023",
          end_date: "12/12/2023",
        },
      ],
      do_update: 0,
      updatedAssessId: 0,
      productDialog: false,
      scheduleTab: "one-off",
      assessBarcodes: [],
      customerDialog: false,
      assessData: {},
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      duplicate: 0,
      contactType: null,
      updateTicketId: 0,
      updateAssessId: 0,
      contactDialog: false,
      update_loading: false,
      dbLineItems: [],
      dbEquipments: [],
      milestoneList: [],
      updatedAssess: {},
      updatedTicket: {},
      routeDialog: false,
      assessBarcode: "",
      ticketBarcode: "",

      dbAssess: {
        title: null,
        description: null,
        is_remark_show: false,
        billing_contact_person: null,
        property_contact_person: null,
        schedule_later: false,
        priority: "3",
        reference: null,
        job_type: null,
        option: "internal",
        tags: null,
        po_number: null,
        quotation: 0,
        payment_mode: null,
        project: null,
        contract: null,
        invoice: null,
        term_conditions: null,
        client_remark: null,
        admin_remark: null,
        milestones: [],
      },
      contractDetail: {},
      vCreated: false,
      vFileUploaded: false,
      vTicket: {},
      vVisit: {},
      vAttachments: [{ file: null, name: null, ext: null }],
      vPdfAttachments: [{ file: null, name: null, ext: null }],
    };
  },
  watch: {
    vCustomerId(param) {
      if (param) {
        this.getVisitOption();
      }
    },
  },
  methods: {
    createCustomerDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    closeDialogWithData() {
      (this.dbAssess.contract = null),
        (this.contractDetail = null),
        (this.dbAssess.project = null),
        (this.dbAssess.milestones = null),
        (this.customerDialog = false);
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.dbAssess.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.dbAssess.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (this.dbAssess.description && this.dbAssess.description.length > 199) {
        e.preventDefault();
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Site Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }
      if (!this.vSchedule.start_time) {
        ErrorEventBus.$emit("update:error", "Please Select Start Time.");
        return false;
      }
      if (!this.vSchedule.end_time) {
        ErrorEventBus.$emit("update:error", "Please Select End Time.");
        return false;
      }
      if (this.vSelectedLineItem && this.vSelectedLineItem.length == 0) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createVisitForm.validate();

      const formErrors = this.validateForm(this.$refs.createVisitForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.routeDialog = true;
      }
    },
    scrollTo(param) {
      this.routeDialog = false;
      this.$nextTick(() => {
        const el = this.$refs[param];
        el?.scrollIntoView({ behavior: "smooth" });
      });
    },
    updateFileName(type, index, param) {
      if (param && param instanceof File) {
        this[type][index].name = param?.name?.split(".")[0];
        this[type][index].ext = param?.name?.split(".")[1];
      } else {
        this[type][index].name = null;
        this[type][index].ext = null;
      }
    },
    addFile(param) {
      this[param].push({ file: null, name: null, ext: null });
    },
    removeFile(param, index) {
      this[param].splice(index, 1);
      if (!this[param].length) {
        this.addFile(param);
      }
    },
    setScheduleType() {
      this.$store.dispatch(SET_SCHEDULE_TYPE, this.scheduleTab);
    },
    openDialog(type) {
      this.customerDialog = false;
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.customerDialog = true;
      } else {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    getVisitOption() {
      this.$store
        .dispatch(GET_OPTION, {
          visit: toSafeInteger(this.updateAssessId),
          job: toSafeInteger(this.updateTicketId),
          do_update: toSafeInteger(this.do_update),
        })
        .then(() => {
          if (!this.updateTicketId) {
            if (this.scheduleTab == "one-off") {
              this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Assessment", route: "assessment" },
                { title: "Create" },
                { title: this.vOption && this.vOption.assess_barcode },
              ]);
            } else {
              this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Assessment", route: "assessment" },
                { title: "Create" },
                { title: ObjectPath.get(this.vOption, "barcode", null) },
              ]);
            }
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          //
        });
    },
    getBalance(row) {
      let balance = Number(row.total_service) - Number(row.visit_count);
      return balance > 0 ? Number(balance) : 0;
    },
    uploadFile(type) {
      let formData = new FormData();

      for (let i = 0; i < this.vAttachments.length; i++) {
        if (
          this.vAttachments[i].file &&
          this.vAttachments[i].file instanceof File
        ) {
          formData.append(`attachments[${i}][file]`, this.vAttachments[i].file);
          formData.append(`attachments[${i}][name]`, this.vAttachments[i].name);
        }
      }

      for (let z = 0; z < this.vPdfAttachments.length; z++) {
        if (
          this.vPdfAttachments[z].file &&
          this.vPdfAttachments[z].file instanceof File
        ) {
          formData.append(
            `pdf-attachments[${z}][file]`,
            this.vPdfAttachments[z].file
          );
          formData.append(
            `pdf-attachments[${z}][name]`,
            this.vPdfAttachments[z].name
          );
        }
      }

      let ticketId = this.vTicket?.id;
      let visitId = this.vVisit?.id;

      let vCustomerId = this.vCustomerId;

      this.formLoading = true;

      this.$store
        .dispatch(UPLOAD, {
          url: `visit-v1/${ticketId}/uploads`,
          data: formData,
        })
        .then(() => {
          this.vFileUploaded = true;
          if (type == "new") {
            this.$store.dispatch(RESET_STATE);
            this.$router
              .push(
                this.getDefaultRoute("assessment.create", {
                  query: { t: Number(new Date()) },
                })
              )
              .then(() => {
                this.$store.dispatch(SET_CUSTOMER, { id: vCustomerId });
              });
          }
          if (type == "detail") {
            this.$store.dispatch(RESET_STATE);
            this.$router.push(
              this.getDefaultRoute("assessment.detail", {
                params: { id: visitId },
                query: { t: Number(new Date()) },
              })
            );
          }
          if (type == "listing") {
            this.$store.dispatch(RESET_STATE);
            this.$router.push(
              this.getDefaultRoute("assessment", {
                query: { t: Number(new Date()), htx: ticketId },
              })
            );
          }
        })
        .catch(() => {
          this.vFileUploaded = false;
        })
        .finally(() => {
          this.routeDialog = false;
          this.formLoading = false;
        });
    },
    getContractDetail(id) {
      this.loading = true;
      if (id) {
        this.$store
          .dispatch(GET, { url: `contract/${id}` })
          .then(({ data }) => {
            this.contractDetail = data;
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /* getLineItems(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(QUERY, {
            url: "line-item/new",
            data,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }, */
    onSubmit(type) {
      if (this.vCreated) {
        this.uploadFile();
        return false;
      }

      const request = {
        ...this.dbAssess,
        dates: this.vDates,
        do_update: toSafeInteger(this.do_update),
        updatedTikcetId: this.updateTicketId,
        updatedAssessId: this.updatedAssessId,
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        tab: this.scheduleTab,
        "one-off": this.scheduleTab == "one-off" ? this.vSchedule : {},
        recurring:
          this.scheduleTab == "recurring" ? this.vRecurringSchedule : {},
        "line-item": this.vSelectedLineItem,
        calculation: this.vCalculations,
        team: this.vTeam,
        assessBarcodes: this.assessBarcodes ? this.assessBarcodes : [],
      };

      this.formLoading = true;
      this.$store
        .dispatch(POST, { url: "visit-v1", data: request })
        .then(({ data }) => {
          this.vCreated = true;
          this.vTicket = data.ticket;
          this.vVisit = data.visit;
          this.uploadFile(type);
        })
        .catch(() => {
          this.vCreated = false;
          this.formLoading = false;
        });
    },
    getAllMilestones(id) {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: `project-milestone/${id}` })
        .then(({ data }) => {
          _this.milestoneList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    initUpdate() {
      this.update_loading = true;
      this.$store
        .dispatch(GET, { url: `job/update?job-id=${this.updateTicketId}` })
        .then((response) => {
          let engineers = ObjectPath.get(response, "data.engineers", []);

          this.updatedAssess = ObjectPath.get(response, "data.visit", {});

          this.$store.commit(SET_EDIT_RECURRING_SCHEDULE, {
            ...this.updatedAssess,
            engineers,
          });

          this.updatedTicket = response.data.ticket;

          this.assessBarcodes = ObjectPath.get(
            response,
            "data.assess_barcode",
            []
          );

          this.ticketBarcode = ObjectPath.get(
            response,
            "data.ticket.barcode",
            ""
          );
          this.assessBarcode = ObjectPath.get(
            response,
            "data.visit.barcode",
            ""
          );
          this.updatedAssessId = ObjectPath.get(
            response,
            "data.visit.id",
            null
          );
          this.dbAssess.reference = ObjectPath.get(
            response,
            "data.ticket.reference",
            null
          );
          this.dbAssess.reference = ObjectPath.get(
            response,
            "data.ticket.reference",
            null
          );
          this.assessData = response.data.visit;

          this.dbAssess.title = ObjectPath.get(
            response,
            "data.ticket.title",
            null
          );
          this.dbAssess.attention = ObjectPath.get(
            response,
            "data.ticket.attention",
            null
          );
          this.dbAssess.schedule_later = ObjectPath.get(
            response,
            "data.visit.schedule_later",
            null
          );
          this.dbAssess.quotation = ObjectPath.get(
            response,
            "data.visit.quotation",
            null
          );
          this.dbAssess.po_number = ObjectPath.get(
            response,
            "data.ticket.po_number",
            null
          );
          this.dbAssess.payment_mode = ObjectPath.get(
            response,
            "data.ticket.payment_mode",
            0
          );
          this.dbAssess.description = ObjectPath.get(
            response,
            "data.ticket.description",
            null
          );
          this.dbAssess.option = ObjectPath.get(
            response,
            "data.ticket.option",
            null
          );
          this.dbAssess.term_conditions = ObjectPath.get(
            response,
            "data.ticket.term_conditions",
            null
          );
          this.dbAssess.admin_remark = ObjectPath.get(
            response,
            "data.ticket.admin_remark",
            null
          );
          this.dbAssess.client_remark = ObjectPath.get(
            response,
            "data.ticket.client_remark",
            null
          );

          this.dbAssess.tags = ObjectPath.get(response, "data.ticket.tags", []);

          this.assessBarcodes = ObjectPath.get(
            response,
            "data.assess_barcode",
            []
          );

          this.dbAssess.job_type = ObjectPath.get(
            response,
            "data.ticket.ticket_type",
            []
          );

          this.dbAssess.project = ObjectPath.get(
            response,
            "data.visit.project",
            0
          );
          this.dbAssess.is_remark_show = ObjectPath.get(
            response,
            "data.ticket.is_remark_show",
            false
          );
          if (this.dbAssess.project > 0) {
            this.getAllMilestones(this.dbAssess.project);
          }
          this.dbAssess.milestones = ObjectPath.get(
            response,
            "data.visit.milestone",
            []
          );
          /* for (let i = 0; i < ticket_type.length; i++) {
            let found = this.lodash.find(this.vOption.ticket_types, {
       
            });
            if (found) {
              this.dbAssess.job_type.push(found);
            }
          } */

          let skills = ObjectPath.get(response, "data.ticket.skills", []);
          this.dbAssess.skill = [];
          for (let i = 0; i < skills.length; i++) {
            let found = this.lodash.find(this.skillList, { value: skills[i] });
            if (found) {
              this.dbAssess.skill.push(found);
            }
          }

          this.dbAssess.property_person_notify = ObjectPath.get(
            response,
            "data.ticket.property_person_notify",
            0
          );
          this.dbAssess.priority = ObjectPath.get(
            response,
            "data.ticket.priority",
            1
          );
          this.dbAssess.contract = ObjectPath.get(
            response,
            "data.ticket.contract",
            0
          );
          this.scheduleTab =
            ObjectPath.get(response, "data.ticket.type", 1) == 1
              ? "one-off"
              : "recurring";
          this.dbAssess.total_occurence = ObjectPath.get(
            response,
            "data.ticket.total_frequency",
            0
          );

          /*if (this.scheduleTab == "one-off") {
            JobScheduleTeamEventBus.$emit(
              "update-value:one-off-schedule",
              ObjectPath.get(response, "data.visit", {})
            );
          } else {
            JobScheduleTeamEventBus.$emit(
              "update-value:recurring-schedule",
              ObjectPath.get(response, "data.visit", {})
            );
          }

          JobEventBus.$emit(
            "update-value:line-item-calculation",
            ObjectPath.get(response, "data.ticket", {})
          );*/

          /*   TermConditionEventBus.$emit(
            "update-value:terms-conditions",
            ObjectPath.get(response, "data.ticket.term_conditions", null)
          );
          NoteAttachmentEventBus.$emit(
            "update-value:internal-notes",
            ObjectPath.get(response, "data.ticket.admin_remark", null)
          );
          NoteAttachmentEventBus.$emit(
            "update-value:attachments",
            ObjectPath.get(response, "data.document", [])
          ); */

          if (this.scheduleTab == "one-off") {
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Assessment", route: "assessment" },
              { title: "Update" },
              { title: ObjectPath.get(response, "data.visit.barcode", null) },
            ]);
          } else {
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Assessment", route: "assessment" },
              { title: "Update" },
              { title: ObjectPath.get(response, "data.ticket.barcode", null) },
            ]);
          }

          this.dbAssess.barcode = ObjectPath.get(
            response,
            "data.ticket.barcode",
            null
          );
          this.dbAssess.contract = ObjectPath.get(
            response,
            "data.ticket.contract",
            null
          );
          this.dbAssess.assess_barcode = ObjectPath.get(
            response,
            "data.visit.barcode",
            null
          );

          if (ObjectPath.get(response, "data.ticket.type", 0) == 1) {
            this.$store.commit(SET_SCHEDULE_TYPE, "one-off");
          } else if (ObjectPath.get(response, "data.ticket.type", 0) == 2) {
            this.$store.commit(SET_SCHEDULE_TYPE, "recurring");
          }

          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.visit.ticket.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.visit.ticket.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.visit.ticket.property_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.visit.ticket.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.visit.ticket.billing_person", {})
          );

          this.$nextTick(() => {
            this.$store.commit(SET_AVAILABLE_TEAM, engineers);
            this.$nextTick(() => {
              this.$store.commit(SET_VISIT_TEAM, {
                engineer: this.lodash.filter(engineers, {
                  assign_status: true,
                }),
                email_team: !!Number(
                  ObjectPath.get(response, "data.ticket.notify_engineer", "0")
                ),
              });
            });
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.update_loading = false;
        });
    },
  },
  components: {
    TinyMCE,
    TagAutoComplete,
    /*  Barcode, */
    CustomerSelect,
    RouteDialog,
    PersonSelect,
    AddressSelect,
    Schedule,
    RecurringSchedule,
    LineItemV2,
    CustomerCreate,
  },
  mounted() {
    this.updateTicketId = toSafeInteger(this.$route.query.job_id);
    this.updateAssessId = toSafeInteger(this.$route.params.id);
    this.scheduleTab = this.$route.query.job_type;
    this.do_update = toSafeInteger(this.$route.query.do_update);
    this.duplicate = toSafeInteger(this.$route.query.duplicate);
    if (this.duplicate > 0) {
      this.$store.dispatch(SET_CUSTOMER, {
        id: toSafeInteger(this.$route.query.customer),
      });
      this.updateTicketId = this.duplicate;
      this.initUpdate();
    }
    if (this.duplicate == 0 && toSafeInteger(this.$route.query.customer) > 0) {
      this.$store.dispatch(SET_CUSTOMER, {
        id: toSafeInteger(this.$route.query.customer),
      });
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Assessment", route: "assessment" },
      { title: "Create" },
    ]);
    // this.getVisitOption();
    this.setScheduleType();
    if (this.updateTicketId && this.updateTicketId > 0 && this.do_update > 0) {
      this.initUpdate();
      /*  this.getVisitOption(); */
    }
    if (this.updateTicketId && this.updateTicketId > 0 && this.do_update > 0) {
      if (this.scheduleTab == "one-off") {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Assessment", route: "assessment" },
          { title: "Create" },
          { title: ObjectPath.get(this.dbAssess, "assess_barcode", null) },
        ]);
      } else {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Assessment", route: "assessment" },
          { title: "Create" },
          { title: ObjectPath.get(this.dbAssess, "barcode", null) },
        ]);
      }
    }
  },
  beforeMount() {
    this.$store.dispatch(RESET_CREATE_STATE);
  },
  beforeDestroy() {
    this.$store.dispatch(RESET_CREATE_STATE);
  },
  computed: {
    ...mapGetters([
      "vOption",
      "vTeam",
      "vDates",
      "vSchedule",
      "vCalculations",
      "vRecurringSchedule",
      "vSelectedLineItem",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
    ]),
    todayTime() {
      return moment().format("DD/MM/YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>
.visit-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
