<template>
  <v-row>
    <v-col :md="showCalendar ? 4 : 12">
      <v-row>
        <v-col :md="showCalendar ? 12 : 8">
          <div class="one-off-schedule">
            <div class="schedule-tab-item-header custom-grey-border p-3">
              <v-layout>
                <v-flex>
                  <h3
                    class="m-0 text-uppercase font-weight-700 color-custom-blue"
                  >
                    Recurring Schedule
                  </h3>
                </v-flex>
                <v-flex class="text-right">
                  <v-btn
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    right
                    depressed
                    v-on:click="showCalendar = !showCalendar"
                  >
                    <v-icon class="mr-2">mdi-calendar</v-icon>
                    <span v-if="showCalendar">Hide Calendar</span>
                    <span v-else>Show Calendar</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
            <div class="schedule-tab-item-body custom-grey-border p-3">
              <v-row>
                <v-col md="12" class="px-4 pb-4">
                  <v-tabs
                    v-model="recurringTab"
                    background-color="transparent"
                    color="cyan"
                    v-on:change="
                      tabChange($event);
                      getDelayScheduleDialog();
                    "
                  >
                    <v-tab class="font-size-14 font-weight-500" href="#daily">
                      Daily
                    </v-tab>
                    <v-tab class="font-size-14 font-weight-500" href="#weekly">
                      Weekly
                    </v-tab>
                    <v-tab class="font-size-14 font-weight-500" href="#monthly">
                      Monthly
                    </v-tab>
                    <!--   <v-tab class="font-size-14 font-weight-500" href="#yearly">
                      Yearly
                    </v-tab> -->
                  </v-tabs>
                  <v-tabs-items v-model="recurringTab">
                    <v-tab-item value="daily">
                      <template v-if="recurringTab == 'daily'">
                        <div
                          class="mt-2"
                          :key="`recurring-schedule-${recurringTab}`"
                        >
                          <v-radio-group
                            mandatory
                            class="mt-0"
                            hide-details
                            v-model="recurring_schedule.recurring_pattern"
                            v-on:change="
                              emitInput();
                              getDelayScheduleDialog();
                            "
                          >
                            <v-radio color="cyan" value="2">
                              <template v-slot:label>
                                <div
                                  class="d-flex align-center"
                                  style="max-width: 200px"
                                  :class="{
                                    'pointer-events-none':
                                      recurring_schedule.recurring_pattern !=
                                      '2',
                                  }"
                                >
                                  <div>Every</div>
                                  <div class="px-4">
                                    <v-text-field
                                      dense
                                      type="number"
                                      filled
                                      class="ml-0 pl-0 text-center"
                                      hide-details
                                      placeholder="Day"
                                      style="max-width: 50px; min-width: 50px"
                                      solo
                                      flat
                                      v-model="
                                        recurring_schedule.daily.day_count
                                      "
                                      color="cyan"
                                      v-on:change="
                                        emitInput();
                                        getDelayScheduleDialog();
                                      "
                                    />
                                  </div>
                                  <div>Day</div>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio label="Weekday" color="cyan" value="1" />
                          </v-radio-group>
                        </div>
                      </template>
                    </v-tab-item>
                    <v-tab-item value="weekly">
                      <template v-if="recurringTab == 'weekly'">
                        <div
                          class="mt-2"
                          :key="`recurring-schedule-${recurringTab}`"
                        >
                          <v-radio-group
                            mandatory
                            class="mt-0"
                            hide-details
                            v-model="recurring_schedule.recurring_pattern"
                            v-on:change="
                              emitInput();
                              getDelayScheduleDialog();
                            "
                          >
                            <v-radio color="cyan" value="1">
                              <template v-slot:label>
                                <div
                                  class="d-flex align-center"
                                  style="max-width: 200px"
                                >
                                  <div>Every</div>
                                  <div class="px-4">
                                    <v-text-field
                                      dense
                                      filled
                                      type="number"
                                      class="ml-0 pl-0 text-center"
                                      hide-details
                                      placeholder="Day"
                                      style="max-width: 50px; min-width: 50px"
                                      solo
                                      flat
                                      v-model="
                                        recurring_schedule.weekly.week_count
                                      "
                                      color="cyan"
                                      v-on:change="
                                        emitInput();
                                        getDelayScheduleDialog();
                                      "
                                    />
                                  </div>
                                  <div>week(s) on</div>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                          <div class="d-flex">
                            <v-checkbox
                              color="cyan"
                              multiple
                              class="mr-5"
                              v-model="recurring_schedule.weekly.weeks"
                              v-for="(weekday, index) in jobWeekDays"
                              :key="`week-day-${index}`"
                              :label="weekday.long_name"
                              :value="weekday.id"
                              v-on:change="
                                emitInput();
                                getDelayScheduleDialog();
                              "
                            />
                          </div>
                        </div>
                      </template>
                    </v-tab-item>
                    <v-tab-item value="monthly">
                      <template v-if="recurringTab == 'monthly'">
                        <div
                          class="mt-2"
                          :key="`recurring-schedule-${recurringTab}`"
                        >
                          <v-radio-group
                            mandatory
                            class="mt-0"
                            hide-details
                            v-model="recurring_schedule.recurring_pattern"
                            v-on:change="
                              emitInput();
                              getDelayScheduleDialog();
                            "
                          >
                            <v-radio color="cyan" value="1">
                              <template v-slot:label>
                                <div
                                  class="d-flex align-center"
                                  style="max-width: 300px"
                                  :class="{
                                    'pointer-events-none':
                                      recurring_schedule.recurring_pattern !=
                                      '1',
                                  }"
                                >
                                  <div>Day</div>
                                  <div class="px-4">
                                    <v-text-field
                                      dense
                                      filled
                                      class="ml-0 pl-0 text-center"
                                      hide-details
                                      placeholder="Day"
                                      style="max-width: 50px; min-width: 50px"
                                      solo
                                      type="number"
                                      flat
                                      v-model="
                                        recurring_schedule.monthly.day_count
                                      "
                                      color="cyan"
                                      v-on:change="
                                        emitInput();
                                        getDelayScheduleDialog();
                                      "
                                    />
                                  </div>
                                  <div>of every</div>
                                  <div class="px-4">
                                    <v-text-field
                                      dense
                                      filled
                                      class="ml-0 pl-0 text-center"
                                      hide-details
                                      placeholder="Day"
                                      style="max-width: 50px; min-width: 50px"
                                      solo
                                      type="number"
                                      flat
                                      v-model="
                                        recurring_schedule.monthly
                                          .day_month_count
                                      "
                                      color="cyan"
                                      v-on:change="
                                        emitInput();
                                        getDelayScheduleDialog();
                                      "
                                    />
                                  </div>
                                  <div>month(s)</div>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio color="cyan" value="2">
                              <template v-slot:label>
                                <div
                                  class="d-flex align-center"
                                  :class="{
                                    'pointer-events-none':
                                      recurring_schedule.recurring_pattern !=
                                      '2',
                                  }"
                                >
                                  <div>The</div>
                                  <div class="pl-4 pr-2">
                                    <v-select
                                      dense
                                      filled
                                      :items="jobDayOfMonths"
                                      item-text="value"
                                      item-value="id"
                                      item-color="cyan"
                                      class="ml-0 pl-0 text-center"
                                      style="max-width: 130px; min-width: 130px"
                                      hide-details
                                      placeholder="Day"
                                      solo
                                      flat
                                      v-model="
                                        recurring_schedule.monthly.week_day
                                      "
                                      color="cyan"
                                      v-on:change="
                                        emitInput();
                                        getDelayScheduleDialog();
                                      "
                                    />
                                  </div>
                                  <div class="pr-4 pl-2">
                                    <v-select
                                      dense
                                      filled
                                      :items="jobWeekDays"
                                      class="ml-0 pl-0 text-center"
                                      hide-details
                                      item-text="long_name"
                                      item-value="id"
                                      item-color="cyan"
                                      placeholder="Day"
                                      style="max-width: 130px; min-width: 130px"
                                      solo
                                      flat
                                      v-model="
                                        recurring_schedule.monthly.week_count
                                      "
                                      color="cyan"
                                      v-on:change="
                                        emitInput();
                                        getDelayScheduleDialog();
                                      "
                                    />
                                  </div>
                                  <div>of every</div>
                                  <div class="px-4">
                                    <v-text-field
                                      dense
                                      filled
                                      class="ml-0 pl-0 text-center"
                                      hide-details
                                      placeholder="Day"
                                      style="max-width: 50px; min-width: 50px"
                                      solo
                                      flat
                                      type="number"
                                      v-model="
                                        recurring_schedule.monthly.month_count
                                      "
                                      color="cyan"
                                      v-on:change="
                                        emitInput();
                                        getDelayScheduleDialog();
                                      "
                                    />
                                  </div>
                                  <div>month(s)</div>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </template>
                    </v-tab-item>
                    <!--   <v-tab-item value="yearly">
                      <template v-if="recurringTab == 'yearly'">
                        <div
                          class="mt-2"
                          :key="`recurring-schedule-${recurringTab}`"
                        >
                          <v-radio-group
                            mandatory
                            class="mt-0"
                            hide-details
                            v-model="recurring_schedule.recurring_pattern"
                            v-on:change="emitInput()"
                          >
                            <v-radio color="cyan" value="2">
                              <template v-slot:label>
                                <div
                                  class="d-flex align-center"
                                  style="max-width: 200px"
                                  :class="{
                                    'pointer-events-none':
                                      recurring_schedule.recurring_pattern !=
                                      '2',
                                  }"
                                >
                                  <div>Every</div>
                                  <div class="px-4">
                                    <v-text-field
                                      dense
                                      type="number"
                                      filled
                                      class="ml-0 pl-0 text-center"
                                      hide-details
                                      placeholder="Day"
                                      style="max-width: 50px; min-width: 50px"
                                      solo
                                      flat
                                      v-model="
                                        recurring_schedule.yearly.day_count
                                      "
                                      color="cyan"
                                      v-on:change="emitInput()"
                                    />
                                  </div>
                                  <div>Year</div>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                      </template>
                    </v-tab-item> -->
                  </v-tabs-items>
                  <v-row class="mt-2">
                    <v-col md="4">
                      <label for="start-date" class="font-weight-600 mx-1"
                        >Start Date</label
                      >
                      <DatePicker
                        key="visit-reschedule-start-date"
                        id="start-date"
                        :min-date="todayDate"
                        placeholder="Start Date"
                        v-model="recurring_schedule.start_date"
                        v-on:change="
                          updateEndDate();
                          emitInput();
                          getDelayScheduleDialog();
                        "
                      />
                    </v-col>
                    <v-col md="4">
                      <label for="start-time" class="font-weight-600 mx-1"
                        >Start Time
                      </label>
                      <TimePicker
                        key="visit-reschedule-start-time"
                        id="start-time"
                        placeholder="Start Time"
                        v-model="recurring_schedule.start_time"
                        :min-time="todayTime"
                        v-on:change="
                          emitInput();
                          getDelayScheduleDialog(), addTime(recurring_schedule);
                        "
                      />
                    </v-col>
                    <v-col md="4">
                      <label for="end-time" class="font-weight-600 mx-1"
                        >End Time</label
                      >
                      <TimePicker
                        key="visit-reschedule-end-time"
                        id="end-time"
                        placeholder="End Time"
                        v-model="recurring_schedule.end_time"
                        :min-time="updatedDate"
                        v-on:change="
                          emitInput();
                          getDelayScheduleDialog();
                        "
                      />
                    </v-col>
                    <v-col md="12">
                      <v-radio-group
                        mandatory
                        class="mt-0"
                        hide-details
                        v-model="recurring_schedule.end_mode"
                        v-on:change="
                          emitInput();
                          getDelayScheduleDialog();
                        "
                      >
                        <v-radio color="cyan" value="2">
                          <template v-slot:label>
                            <div
                              class="d-flex align-center"
                              style="max-width: 400px"
                              :class="{
                                'pointer-events-none':
                                  recurring_schedule.end_mode != '2',
                              }"
                            >
                              <div>End after</div>
                              <div class="px-4">
                                <v-text-field
                                  dense
                                  filled
                                  class="ml-0 pl-0 text-center"
                                  hide-details
                                  placeholder="Occurrence"
                                  style="max-width: 50px; min-width: 50px"
                                  solo
                                  flat
                                  type="number"
                                  color="cyan"
                                  v-model="recurring_schedule.occurrence"
                                  v-on:change="
                                    emitInput();
                                    getDelayScheduleDialog();
                                  "
                                />
                              </div>
                              <div>occurrences</div>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio color="cyan" value="3">
                          <template v-slot:label>
                            <div
                              class="d-flex align-center"
                              style="max-width: 400px"
                              :class="{
                                'pointer-events-none':
                                  recurring_schedule.end_mode != '3',
                              }"
                            >
                              <div>End after</div>
                              <div class="px-4">
                                <DatePicker
                                  key="visit-reschedule-end-date"
                                  id="end-date"
                                  placeholder="End Date"
                                  v-model="recurring_schedule.end_date"
                                  :min-date="recurring_schedule.start_date"
                                  v-on:change="
                                    emitInput();
                                    getDelayScheduleDialog();
                                  "
                                />
                              </div>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col md="12">
                      <v-row>
                        <v-col md="8">
                          <p
                            v-if="scheduleLoading"
                            style="min-height: 47.5px"
                            class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                          >
                            <v-progress-linear
                              color="cyan"
                              indeterminate
                              rounded
                              height="6"
                              style="margin: 6px 0px"
                            ></v-progress-linear>
                          </p>
                          <p
                            v-else
                            style="min-height: 47.5px"
                            class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                          >
                            {{ message }}
                          </p>
                        </v-col>
                        <v-col>
                          <v-btn
                            style="min-height: 47.5px"
                            v-if="resetSchedule"
                            :disabled="scheduleLoading"
                            :loading="scheduleLoading"
                            v-on:click="getScheduleDialog()"
                            color="red lighten-2 white--text"
                            class="custom-bold-button height-100 w-100"
                            :class="{ 'red-glowing-btn': !scheduleLoading }"
                            depressed
                            >Get Schedule</v-btn
                          >
                          <v-btn
                            v-else
                            style="min-height: 47.5px"
                            :disabled="scheduleLoading"
                            :loading="scheduleLoading"
                            v-on:click="showScheduleDialog()"
                            color="cyan white--text"
                            class="custom-bold-button height-100 w-100"
                            depressed
                            >Show Schedule</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col :md="showCalendar ? 12 : 4">
          <VisitTeam
            :engineer-arr="ticketDetails.engineers"
            :key="`visit-reschedule-team-${teamDialogKey}`"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="showCalendar" md="8">
      <Calendar :key="`visit-reschedule-calendar-${calendarDialogKey}`" />
    </v-col>
    <RecurringScheduleDialog
      :key="`visit-reschedule-list-${dialogKey}`"
      :schedule-dialog="showSchedule"
      :response-schedule="responseSchedule"
      :visit-schedule="visitSchedule"
      :raw-visit-schedule="rawVisitSchedule"
      v-on:close="showSchedule = false"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import VisitTeam from "@/view/pages/job/visit-partials/Team.vue";
import RecurringScheduleDialog from "@/view/pages/job/visit-partials/Recurring-Schedule-Dialog.vue";
import Calendar from "@/view/pages/partials/Calendar.vue";
import TimePicker from "@/view/components/TimePicker.vue";
import DatePicker from "@/view/components/QDatePicker.vue";
import {
  SET_AVAILABLE_TEAM,
  SET_VISIT_TEAM,
  SET_RECURRING_SCHEDULE,
  SET_VISIT_DATES,
} from "@/core/services/store/visit.module";
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";
// import { isEmpty } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "visit-recurring-schedule",
  data() {
    return {
      recurringTab: "daily",
      showCalendar: false,
      showSchedule: false,
      resetSchedule: true,
      teamDialogKey: Number(new Date()),
      calendarDialogKey: Number(new Date()),
      dialogKey: Number(new Date()),
      recurring_schedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        tab: null,
        message: null,
        occurrence: "1",
        recurring_pattern: "2",
        end_mode: "3",
        daily: {
          day_count: "1",
        },
        yearly: {
          day_count: "1",
        },
        weekly: {
          week_count: "1",
          weeks: [1],
        },
        monthly: {
          day_count: "1",
          week_day: 1,
          week_count: 1,
          day_month_count: "1",
          month_count: "1",
        },
      },
      jobWeekDays: [
        { id: 1, short_name: "Sun", long_name: "Sunday" },
        { id: 2, short_name: "Mon", long_name: "Monday" },
        { id: 3, short_name: "Tue", long_name: "Tuesday" },
        { id: 4, short_name: "Wed", long_name: "Wednesday" },
        { id: 5, short_name: "Thu", long_name: "Thursday" },
        { id: 6, short_name: "Fri", long_name: "Friday" },
        { id: 7, short_name: "Sat", long_name: "Saturday" },
      ],
      jobDayOfMonths: [
        { id: 1, value: "First" },
        { id: 2, value: "Second" },
        { id: 3, value: "Third" },
        { id: 4, value: "Fourth" },
        { id: 5, value: "Last" },
      ],
      responseSchedule: {},
      visitSchedule: [],
      rawVisitSchedule: [],
      scheduleLoading: false,
      message: null,
      todayDate: null,
      scheduleTimeout: null,
      scheduleDelay: 500,
      setEditEngineer: false,
      editEngineers: [],
    };
  },
  props: {
    visitDetails: {
      type: Object,
      default: null,
    },
    ticketDetails: {
      type: Object,
      default: null,
    },
  },
  watch: {
    vEditRecurringSchedule: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param?.id) {
          this.editEngineers = param?.engineers ?? [];
          this.setEditEngineer = true;
          this.recurring_schedule.start_date = param?.start_date;
          this.recurring_schedule.end_date = param?.end_date;
          this.recurring_schedule.start_time = moment(
            `${param?.start_date} ${param?.start_time}`
          ).format("hh:mm A");
          this.recurring_schedule.end_time = moment(
            `${param?.end_date} ${param?.end_time}`
          ).format("hh:mm A");
          this.recurring_schedule.tab = param?.type;
          this.recurringTab = param?.type;
          this.recurring_schedule.message = null;
          this.recurring_schedule.occurrence = param?.occurrence ?? "1";
          this.recurring_schedule.recurring_pattern =
            param?.recurring_pattern ?? "2";
          this.recurring_schedule.end_mode = param?.end_mode ?? "3";
          this.recurring_schedule.daily = {
            day_count: param?.day_count ?? "1",
          };
          this.recurring_schedule.yearly = {
            day_count: param?.day_count ?? "1",
          };
          this.recurring_schedule.weekly = {
            week_count: param?.week_count ?? "1",
            weeks: param?.weeks ?? [1],
          };
          this.recurring_schedule.monthly = {
            day_count: param?.day_count ?? "1",
            week_day: param?.week_day ?? 1,
            week_count: param?.week_count ?? 1,
            day_month_count: param?.day_month_count ?? "1",
            month_count: param?.month_count ?? "1",
          };

          this.getDelayScheduleDialog();
        }
      },
    },
  },
  components: {
    VisitTeam,
    RecurringScheduleDialog,
    Calendar,
    TimePicker,
    DatePicker,
  },
  methods: {
    setEditTeam() {
      if (!this.setEditEngineer) {
        return false;
      }
      this.setEditEngineer = false;
      this.$nextTick(() => {
        this.$store.commit(SET_AVAILABLE_TEAM, this.editEngineers);
        this.$nextTick(() => {
          this.$store.commit(SET_VISIT_TEAM, {
            engineer: this.lodash.filter(this.editEngineers, {
              assign_status: true,
            }),
            email_team: false,
          });
        });
      });
    },
    getDelayScheduleDialog() {
      let _this = this;
      clearTimeout(_this.scheduleTimeout);
      _this.scheduleTimeout = setTimeout(function () {
        _this.getScheduleDialog();
      }, _this.scheduleDelay);
    },
    updateEndDate() {
      this.recurring_schedule.end_date = moment(
        this.recurring_schedule.start_date
      )
        .add(1, "day")
        .format("YYYY-MM-DD");
    },
    getScheduleDialog() {
      this.$nextTick(() => {
        this.getRecurrence();
      });
    },
    getRecurrencePromise() {
      const _this = this;
      const type = _this.recurringTab;
      return new Promise((resolve, reject) => {
        try {
          const start_time = _this.recurring_schedule.start_time
            ? moment(_this.recurring_schedule.start_time, ["hh:mm A"]).format(
                "HH:mm"
              )
            : null;
          const end_time = _this.recurring_schedule.end_time
            ? moment(_this.recurring_schedule.end_time, ["hh:mm A"]).format(
                "HH:mm"
              )
            : null;
          const recurrenceData = _this.recurring_schedule[type];
          let formData = new Object();
          switch (type) {
            case "daily": {
              formData = {
                day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                occurrence: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.occurrence
                ),
                start_date: _this.recurring_schedule.start_date,
                end_date: _this.recurring_schedule.end_date,
                recurring_pattern: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.recurring_pattern
                ),
                start_time,
                end_time,
                end_mode: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.end_mode
                ),
              };
              if (Number(formData.recurring_pattern) === 1) {
                formData.day_count = 1;
              }
              break;
            }
            case "weekly": {
              formData = {
                weeks: _this.lodash.isArray(recurrenceData.weeks)
                  ? recurrenceData.weeks.join(",")
                  : null,
                week_count: _this.lodash.toSafeInteger(
                  recurrenceData.week_count
                ),
                occurrence: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.occurrence
                ),
                start_time,
                end_time,
                start_date: _this.recurring_schedule.start_date,
                end_date: _this.recurring_schedule.end_date,
                end_mode: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.end_mode
                ),
              };
              break;
            }
            case "monthly": {
              formData = {
                week_day: _this.lodash.toSafeInteger(recurrenceData.week_day),
                day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                week_count: _this.lodash.toSafeInteger(
                  recurrenceData.week_count
                ),
                month_count:
                  _this.lodash.toSafeInteger(
                    _this.recurring_schedule.recurring_pattern
                  ) === 1
                    ? _this.lodash.toSafeInteger(recurrenceData.day_month_count)
                    : _this.lodash.toSafeInteger(recurrenceData.month_count),
                recurring_pattern: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.recurring_pattern
                ),
                occurrence: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.occurrence
                ),
                start_time,
                end_time,
                start_date: _this.recurring_schedule.start_date,
                end_date: _this.recurring_schedule.end_date,
                end_mode: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.end_mode
                ),
              };
              break;
            }
            case "yearly": {
              formData = {
                day_count: _this.lodash.toSafeInteger(recurrenceData.day_count),
                occurrence: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.occurrence
                ),
                start_date: _this.recurring_schedule.start_date,
                end_date: _this.recurring_schedule.end_date,
                recurring_pattern: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.recurring_pattern
                ),
                start_time,
                end_time,
                end_mode: _this.lodash.toSafeInteger(
                  _this.recurring_schedule.end_mode
                ),
              };
              break;
            }
          }
          if (
            _this.lodash.isEmpty(recurrenceData) === false &&
            _this.lodash.isEmpty(formData) === false &&
            formData.start_date &&
            moment(formData.start_date).isValid()
          ) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/recurrence/" + type,
                data: formData,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve({});
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getScheduelDialog() {
      this.$nextTick(() => {
        this.getRecurrence();
      });
    },
    showScheduleDialog() {
      this.dialogKey = Number(new Date());
      this.$nextTick(() => {
        this.showSchedule = true;
      });
    },
    getRecurrence() {
      this.scheduleLoading = true;
      this.resetSchedule = true;
      this.message = null;
      this.visitSchedule = [];
      this.getRecurrencePromise()
        .then((response) => {
          this.resetSchedule = false;
          this.responseSchedule = {
            start_date: response.start_date,
            end_date: response.end_date,
            start_time: response.start_time
              ? moment(response.start_time, ["HH:mm"]).format("h:mm A")
              : null,
            end_time: response.end_time
              ? moment(response.end_time, ["HH:mm"]).format("h:mm A")
              : null,
          };

          this.message = response?.message;

          if (response?.dates && response?.dates.length) {
            this.visitSchedule = response?.dates?.map((row) => {
              return {
                status: 1,
                is_edited: 0,
                visit_date: row,
                start_time: response.start_time
                  ? moment(response.start_time, ["HH:mm"]).format("hh:mm A")
                  : null,
                end_time: response.end_time
                  ? moment(response.end_time, ["HH:mm"]).format("hh:mm A")
                  : null,
              };
            });

            this.rawVisitSchedule = this.lodash.cloneDeep(this.visitSchedule);
          }

          this.setEditTeam();

          this.emitSchedule();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.scheduleLoading = false;
        });
    },
    addTime(row) {
      this.recurring_schedule.end_time = moment(row.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
    tabChange(param) {
      if (param == "daily") {
        this.recurring_schedule.recurring_pattern = "2";
        this.recurring_schedule.daily.day_count = "1";
        this.recurring_schedule.end_date = moment()
          .add(1, "day")
          .format("YYYY-MM-DD");
      }
      if (param == "weekly") {
        this.recurring_schedule.recurring_pattern = "2";
        this.recurring_schedule.weekly.week_count = "1";
        this.recurring_schedule.weekly.weeks = [1];
        this.recurring_schedule.end_date = moment()
          .add(1, "week")
          .format("YYYY-MM-DD");
      }
      if (param == "monthly") {
        this.recurring_schedule.recurring_pattern = "1";
        this.recurring_schedule.monthly.day_count = "1";
        this.recurring_schedule.monthly.week_day = 1;
        this.recurring_schedule.monthly.week_count = 1;
        this.recurring_schedule.monthly.day_month_count = "1";
        this.recurring_schedule.monthly.month_count = "1";
        this.recurring_schedule.end_date = moment()
          .add(1, "month")
          .format("YYYY-MM-DD");
      }
      if (param == "yearly") {
        this.recurring_schedule.recurring_pattern = "1";
        this.recurring_schedule.daily.day_count = "1";
        this.recurring_schedule.end_date = moment()
          .add(1, "year")
          .format("YYYY-MM-DD");
      }
      this.$nextTick(() => {
        this.emitInput();
      });
    },
    restoreSchedule() {
      this.$nextTick(() => {
        this.resetSchedule = true;
        this.message = null;
      });
    },
    emitSchedule() {
      this.recurring_schedule.tab = this.recurringTab;
      this.recurring_schedule.message = this.message;
      this.$store.dispatch(SET_VISIT_DATES, this.visitSchedule);
      this.$store.dispatch(SET_RECURRING_SCHEDULE, this.recurring_schedule);
    },
    emitInput() {
      this.emitSchedule();
      this.restoreSchedule();
    },
  },
  mounted() {
    this.emitInput();
    this.todayDate = moment().format("YYYY-MM-DD");
  },
  beforeMount() {
    if (!this.recurring_schedule.start_date) {
      this.recurring_schedule.start_date = moment().format("YYYY-MM-DD");
    }

    if (!this.recurring_schedule.end_date) {
      this.recurring_schedule.end_date = moment()
        .add(1, "day")
        .format("YYYY-MM-DD");
    }

    let currentTime = moment();
    let currentHour = moment().hour();
    let targetTime = moment().set({ hour: currentHour, minute: "00" });

    while (targetTime.isBefore(currentTime)) {
      targetTime.add("30", "minute");
    }

    if (!this.recurring_schedule.start_time) {
      this.recurring_schedule.start_time = targetTime.format("hh:mm A");
    }

    if (!this.recurring_schedule.end_time) {
      this.recurring_schedule.end_time = targetTime
        .add(30, "minute")
        .format("hh:mm A");
    }

    let date = moment().add(15, "minute").format("mm");
    let minute = (Math.round(date / 15) * 15) % 60;
    let datetime = new Date();
    datetime.setHours(moment().add(1, "hour").format("HH"));
    datetime.setMinutes(minute);

    if (!this.recurring_schedule.start_time) {
      this.recurring_schedule.start_time = moment(datetime).format("hh:mm A");
    }

    if (!this.recurring_schedule.end_time) {
      this.recurring_schedule.end_time = moment(datetime).format("hh:mm A");
    }
  },
  computed: {
    ...mapGetters(["vEditRecurringSchedule"]),
    todayTime() {
      return moment(this.recurring_schedule.start_date).format("hh:mm A");
    },
    updatedDate() {
      return moment(this.recurring_schedule.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
  },
};
</script>
