<template>
  <v-row>
    <v-col :md="showCalendar ? 4 : 12">
      <v-row>
        <v-col :md="showCalendar ? 12 : 8">
          <div class="one-off-schedule">
            <div class="schedule-tab-item-header custom-grey-border p-3">
              <v-layout>
                <v-flex>
                  <h3
                    class="m-0 text-uppercase font-weight-700 color-custom-blue"
                  >
                    Schedule
                  </h3>
                </v-flex>
                <v-flex class="text-right">
                  <v-btn
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    right
                    depressed
                    v-on:click="showCalendar = !showCalendar"
                  >
                    <v-icon class="mr-2">mdi-calendar</v-icon>
                    <span v-if="showCalendar">Hide Calendar</span>
                    <span v-else>Show Calendar</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
            <div class="schedule-tab-item-body custom-grey-border p-3">
              <v-row>
                <v-col :md="showCalendar ? 6 : 3" class="px-4 pb-4">
                  <label for="schedule-start-date">Start Date</label>
                  <DatePicker
                    key="visit-schedule-start-date"
                    id="schedule-start-date"
                    placeholder="Start Date"
                    v-model="schedule.start_date"
                    v-on:change="
                      updateEndDate();
                      emitInput();
                    "
                  />
                </v-col>
                <v-col :md="showCalendar ? 6 : 3" class="px-4 pb-4">
                  <label for="schedule-start-time">Start Time </label>
                  <TimePicker
                    key="visit-schedule-start-time"
                    id="schedule-start-time"
                    placeholder="Start Time"
                    v-model="schedule.start_time"
                    :min-time="todayTime"
                    v-on:change="emitInput(), addTime(schedule)"
                  />
                </v-col>
                <v-col :md="showCalendar ? 6 : 3" class="px-4 pb-4">
                  <label for="schedule-end-date">End Date</label>
                  <DatePicker
                    key="visit-schedule-end-date"
                    id="schedule-end-date"
                    placeholder="End Date"
                    v-model="schedule.end_date"
                    :min-date="schedule.start_date"
                    v-on:change="emitInput()"
                  />
                </v-col>
                <v-col :md="showCalendar ? 6 : 3" class="px-4 pb-4">
                  <label for="schedule-end-time">End Time </label>
                  <TimePicker
                    key="visit-schedule-end-time"
                    id="schedule-end-time"
                    placeholder="End Time"
                    v-model="schedule.end_time"
                    :min-time="updatedDate"
                    v-on:change="emitInput()"
                  />
                </v-col>
                <v-col md="12" class="pl-5 pr-4">
                  <p
                    class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                  >
                    {{ schedule.message }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col :md="showCalendar ? 12 : 4">
          <VisitTeam key="visit-schedule-team" />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="showCalendar" md="8">
      <Calendar key="visit-schedule-calendar" />
    </v-col>
  </v-row>
</template>

<script>
import VisitTeam from "@/view/pages/job/visit-partials/Team.vue";
import Calendar from "@/view/pages/partials/Calendar.vue";
import TimePicker from "@/view/components/TimePicker.vue";
import DatePicker from "@/view/components/QDatePicker.vue";
import { SET_SCHEDULE } from "@/core/services/store/visit.module";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "visit-schedule",
  data() {
    return {
      showCalendar: false,
      schedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        message: null,
      },
    };
  },
  components: {
    VisitTeam,
    Calendar,
    TimePicker,
    DatePicker,
  },
  props: {
    visitData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    visitData: {
      deep: true,
      immediate: true,
      handler(param) {
        if (!isEmpty(param)) {
          this.schedule.start_date = moment(param.started_at).format(
            "YYYY-MM-DD"
          );
          this.schedule.end_date = moment(param.finished_at).format(
            "YYYY-MM-DD"
          );
          this.schedule.start_time = moment(param.started_at).format("hh:mm A");
          this.schedule.end_time = moment(param.finished_at).format("hh:mm A");
          this.emitInput();
        }
      },
    },
  },
  methods: {
    updateEndDate() {
      this.schedule.end_date = moment(this.schedule.start_date)
        .add(1, "day")
        .format("YYYY-MM-DD");
    },
    removeTeam(id) {
      this.assigned_id = 0;
      this.lodash.remove(this.assigned_team, (row) => {
        return row.id == id;
      });
    },
    emitInput() {
      let start_date = moment(this.schedule.start_date).format("DD MMMM YYYY");
      let end_date = moment(this.schedule.end_date).format("DD MMMM YYYY");

      this.schedule.message = `This job will start on ${start_date} ${
        this.schedule.start_time ? this.schedule.start_time : ""
      } and finish on ${end_date} ${
        this.schedule.end_time ? this.schedule.end_time : ""
      }`;

      this.$store.dispatch(SET_SCHEDULE, this.schedule);
    },
    addTime(row) {
      this.schedule.end_time = moment(row.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
    updateTeam() {
      this.assigned_team = {};
      let assigned_team = this.lodash.find(this.teams, {
        id: this.assigned_id,
      });
      if (assigned_team) {
        this.assigned_team = { ...assigned_team };
      }
    },
  },
  mounted() {
    this.emitInput();
  },
  beforeMount() {
    if (!this.schedule.start_date) {
      this.schedule.start_date = moment().format("YYYY-MM-DD");
    }

    if (!this.schedule.end_date) {
      this.schedule.end_date = moment().format("YYYY-MM-DD");
    }

    let currentTime = moment();
    let currentHour = moment().hour();
    let targetTime = moment().set({ hour: currentHour, minute: "00" });

    while (targetTime.isBefore(currentTime)) {
      targetTime.add("30", "minute");
    }

    if (!this.schedule.start_time) {
      this.schedule.start_time = targetTime.format("hh:mm A");
    }

    if (!this.schedule.end_time) {
      this.schedule.end_time = targetTime.add(30, "minute").format("hh:mm A");
    }

    let date = moment().add(15, "minute").format("mm");
    let minute = (Math.round(date / 15) * 15) % 60;
    let datetime = new Date();
    datetime.setHours(moment().add(1, "hour").format("HH"));
    datetime.setMinutes(minute);

    /*  if (!this.schedule.start_time) {
      this.schedule.start_time = moment(datetime).format("hh:mm A");
    }

    if (!this.schedule.end_time) {
      this.schedule.end_time = moment(datetime).format("hh:mm A");
    } */
  },
  computed: {
    todayTime() {
      return moment(this.schedule.start_date).format("hh:mm A");
    },
    updatedDate() {
      return moment(this.schedule.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
  },
};
</script>
